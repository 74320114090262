/* Synced with search.common.js, unable to call function outside js file */
function initializeReadMoreParagraph () {
  $('.js-readMoreParagraph').each(function ( index ) {
    var lineHeight = $(this).data('readmore-line-height') || 21;
    var numLines = $(this).data('readmore-line-count') || 3;
    var margin = $(this).data('readmore-line-margin') || 0;
    var expandLabel = $(this).data('readmore-expand-label') || '続きを見る';
    var expandLabelEn = $(this).data('readmore-expand-label-en') || 'Expand';
    var collapseLabel = $(this).data('readmore-collapse-label') || '閉じる';
    var collapseLabelEn = $(this).data('readmore-collapse-label-en') || 'Collapse';

    var selectedReadMore = $(this);

    var language = $('html').attr('lang');
    var moreLabel = expandLabel
    var lessLabel = collapseLabel

    if (language === 'en') {
      moreLabel = expandLabelEn
      lessLabel = collapseLabelEn
    }

    $(selectedReadMore).readmore({
      speed: 200,
      collapsedHeight: (lineHeight * numLines) + margin,
      heightMargin: lineHeight * 0,
      moreLink: '<a class="accBtn">' + moreLabel + '</a>',
      lessLink: '<a class="accBtn">' + lessLabel + '</a>',
      embedCSS: false
    });

    $('#tab-ctl-01, #tab-ctl-02, #tab-ctl-03').on('change',function(e){
      var marker = $(e.currentTarget).attr('id').slice(-2);
      var $targetPanel = $(e.currentTarget)
                        .siblings('.body')
                        .children('#tab-panel-' + marker);
      var $target = $targetPanel.children(selectedReadMore);

      $target.readmore({
        speed: 200,
        collapsedHeight: lineHeight * numLines,
        heightMargin: lineHeight * 0,
        moreLink: '<a>' + moreLabel + '</a>',
        lessLink: '<a>' + lessLabel + '</a>',
        embedCSS: false
      });
    });

    $(window).on('resize', function(e){
      $(selectedReadMore).readmore({
        speed: 200,
        collapsedHeight: lineHeight * numLines,
        heightMargin: lineHeight * 0,
        moreLink: '<a>' + moreLabel + '</a>',
        lessLink: '<a>' + lessLabel + '</a>',
        embedCSS: false
      });
    });
  });
}

$(document).ready(function(){
  $('.module-catalogueSearchResult').adjustCatalogueCassetteWidth();
  $('.module-productImageViewer').productImageViewer();
  //$('.js-readMoreParagraph').readMoreParagraph();
    
  $('.header_global').on('click', function() {
    $(this).toggleClass('is-close');
    $('.header_globalMenu').toggleClass('is-close');
    return false;
  });
  $('.header_global').hover(function(){
    $(this).removeClass('is-close');
    $('.header_globalMenu').removeClass('is-close');
 }, function(){
    $(this).addClass('is-close');
    $('.header_globalMenu').addClass('is-close');
  });
  $('.header_user').hover(function(){
    $(this).removeClass('is-close');
    $('.header_userMenu').removeClass('is-close');
  }, function(){
    $(this).addClass('is-close');
    $('.header_userMenu').addClass('is-close');
  });


  /* タグ削除 */
  $('.js-closeTag').on('click',function(){
    $(this).parent('.tag').hide();
  });

  /* アコーディオン表示 */
  $('.js-accordionBtn').on('click',function(){
    var iconElem = $(this).find('.icon');
    if(iconElem.hasClass('icon-minus')){
      iconElem.removeClass('icon-minus').addClass('icon-plus');
    }else if(iconElem.hasClass('icon-plus')){
      iconElem.removeClass('icon-plus').addClass('icon-minus');
    }else if(iconElem.hasClass('icon-minusBorder')){
      iconElem.removeClass('icon-minusBorder').addClass('icon-plusBorder');
    }else if(iconElem.hasClass('icon-plusBorder')){
      iconElem.removeClass('icon-plusBorder').addClass('icon-minusBorder');
    }
    $(this).parent('.js-accordion').toggleClass('is-open')
  });

  //このページのトップへ
  var $scrolltop = $("#scrollTop");
  $scrolltop.on("touchstart click",function(){
    $("html, body").animate({scrollTop:0},300,"swing");
  });   

  //カテゴリーメガメニュー for search-web
  //中階層
  var $middleLayer = $(".searchTopNav-top > li:first-child");
  
  $(".dropdown-toggle").on('mouseover', function(){
    $(".popover").css("display", "none");
    $("a.maintainHover").removeClass("maintainHover");
  });
  
  $middleLayer.on('mouseover', function(){
    $middleLayer.addClass('is-active');
  });
  
  $middleLayer.on('mouseout', function(){
    $middleLayer.removeClass('is-active');
  });

  //最下層
  var $menu = $(".dropdown-menu");

  // Hook up events to be fired on menu row activation.
  $menu.menuAim({
      activate: activateSubmenu,
      deactivate: deactivateSubmenu
  });

  function activateSubmenu(row) {
      var $row = $(row),
          submenuId = $row.data("submenuId"),
          $submenu = $("#" + submenuId),
          height = $menu.outerHeight(),
          width = $menu.outerWidth();

      // Show the submenu
      $submenu.css({
          display: "block",
          top: "-2px",
          left: width - 4,  // main should overlay submenu
          height:height  // padding for main dropdown's arrow
      });

      // Keep the currently activated row's highlighted look
      $row.find("a").addClass("maintainHover");
  }

  function deactivateSubmenu(row) {
      var $row = $(row),
          submenuId = $row.data("submenuId"),
          $submenu = $("#" + submenuId);

      $submenu.css("display", "none");
      $row.find("a").removeClass("maintainHover");
  }

  $(document).click(function() {
      $(".popover").css("display", "none");
      $("a.maintainHover").removeClass("maintainHover");
  });

  //横スクロールパンくずリスト
  if($('#breadCrumb').length) {
	  var scrollObj = new IScroll("#breadCrumb", {scrollX : true, scrollY : false, mouseWheel : true});
  }

  //型式詳細カタログダウンロードボタン
  $('.js-toggleDownloadList').documentDownloadButtons();
  
  //型式詳細ロット価格テーブルの展開
  $('.js-lotPriceDrawer').lotPerPriceTable();

  //seller詳細プラボのもっと見る
  $('.js-expandRegulationTxt').expandRegulationTxt();

    initializeReadMoreParagraph();

});

/*型式詳細カタログダウンロードボタン*/
(function($){
  $.fn.documentDownloadButtons = function(options){
    
    var $elements = this;

    $elements.each(function(){
      var $documentDownloadList = $(this).next('.documentDownloadList');

      $documentDownloadList.hide();
      $(this).parent().on('mouseenter click',function(){
        $documentDownloadList.slideToggle(200);
      });

      $(this).parent().on('mouseleave',function(){
        $documentDownloadList.hide(200);
      });

    });

    return this;
  }


}(jQuery));

/*もっと見る(多分Seller詳細のプラボの部分のみ)*/
(function($){
  $.fn.expandRegulationTxt = function() {
    var $self = this;

    $self.each(function(){
      var $readMoreBtn = $(this).siblings('.readMore');

      $readMoreBtn.on('click' ,function(e){
        $self.css({
          overflow:'initial',
          height: 'auto'
        });
        $(this).hide();
      });
    });
  };
  return this;
}(jQuery));

/*もっと見る(テキスト)
(function($){
  $.fn.readMoreParagraph = function(options) {
    var $self = this;

    $self.each(function(){
      var $toggle = $(this).children('.js-readMoreToggle');
      var $textArea = $(this).children('p');

      $toggle.on('click', function(e){
        e.preventDefault();
        $textArea.toggleClass('expand');
        $textArea.hasClass('expand') ? $(this).text('閉じる') : $(this).text('続きを見る');
      });
    });


    return this;
  }
}(jQuery));
*/

/*ロット/価格のテーブル展開*/
(function($){
  $.fn.lotPerPriceTable = function(options) {
    var $self = this;

    $self.each(function(){
      var $hiddenRows = $(this).find('.js-hidden'),
          $toggle = $(this).find('.js-toggleLotTable');

      $toggle.on('click', function(e){
        e.preventDefault();
        $hiddenRows.slideToggle();
        $(this).hide().off('click');
      });
    });

  }

  return this;

}(jQuery));

/*製品詳細のサムネイル切り替え*/
(function($){
  $.fn.productImageViewer = function(options) {
    var $self = this;

    $self.each(function(){
      var $selectedImage = $(this).children('.selectedImage').children('img');
      var $thumbnails = $(this).children('.thumbnails').children('.thumbnail');
      var $currentImage = $(this).find('.select');
  
      $selectedImage.imagezoomsl({
        zoomrange: [4, 6],
        magnifiersize: [600, 600],
      })

      $thumbnails.each(function(){
        $(this).on('click',function(e){
          var clickedImageSrc = $(e.currentTarget).children('img').attr('src');
          var clickedImageDataLarge = $(e.currentTarget).children('img').attr('data-large');
          //var $thumbnail = $(this).parent('.thumbnail')
          $selectedImage.attr('src',clickedImageSrc)
                        .attr({'data-large': (clickedImageDataLarge ? clickedImageDataLarge : clickedImageSrc )})
                        ;
          $(this).addClass('select');
          $currentImage.removeClass('select');
          $currentImage = $(this);
        });
      });
    });

    return this;
  }
}(jQuery)); 

/*カタログ検索結果一覧のカセットサイズ自動調整 */
(function($){
  $.fn.adjustCatalogueCassetteWidth = function(options) {
    var $self = this;
    var $catalogueCassetts = $self.children('.catalogueLists').children('li');

    $(window).on('load',function(e){
      var maxHeight = 0;
      $catalogueCassetts.each(function(){
        maxHeight = Math.max(maxHeight,$(this).height());
      });

      /*$catalogueCassetts.each(function(){
        $(this).css({
          height : maxHeight
        });
      });*/
    });

    $(window).on('load resize',function(e){
      var rootWidth = $self.width();
      var columnNumber = rootWidth / 240;
      
      if (rootWidth > 768) {
        $catalogueCassetts.css({
          width: (100 / (columnNumber + 1)) + '%'
        });
      } else if ((rootWidth > 480) && (rootWidth <= 768)) {
        $catalogueCassetts.css({
          width: (100 / 3) + '%'
        });
      } else {
        $catalogueCassetts.css({
          width: (100 / 2) + '%'
        });
      }
    });

    return this;
  }
}(jQuery));


(function($){
  $(document).ready(function(){

    // ITEM PAGE - to check the height of the spec
    if($(window).width() > 768 && $('.spec-list .spec-item').length > 10){
      var listHeight = 0;
      var endElement = $('.item-spec-table .spec-list .spec-item:nth-child(9)').offset().top + $('.item-spec-table .spec-list .spec-item:nth-child(9)').outerHeight()
      var firstElement = $('.item-spec-table .spec-list .spec-item:nth-child(1)').offset().top;
      listHeight = endElement - firstElement;
      $('.item-spec-table .js_readmore').data('height', listHeight);
    } else if ($(window).width() <= 768 && $('.spec-list .spec-item').length > 5){
      var listHeight = 0;
      var endElement = $('.item-spec-table .spec-list .spec-item:nth-child(5)').offset().top + $('.item-spec-table .spec-list .spec-item:nth-child(5)').outerHeight()
      var firstElement = $('.item-spec-table .spec-list .spec-item:nth-child(1)').offset().top;
      listHeight = endElement - firstElement;
      $('.item-spec-table .js_readmore').data('height', listHeight);
    }

    // var tooltip = $('[data-toggle="tooltip"]').tooltip({
    //   html: true,
    //   title: function(e){
    //     return $(this).find('.video-src').html();
    //   },
    //   template: '<div class="tooltip search-tooltip white" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    // });
  });  
}(jQuery));
